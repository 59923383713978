// AuthContextProvider.js
import React, { useState, useEffect } from "react";
import DiscussionContext from "./DiscussionContext";
import { io } from "socket.io-client";
import MessageService from "../services/MessagesService";
import StudentService from "../services/StudentService";
import ProfessorService from "../services/ProfessorService";
import { UserType } from "../utils/UserType";
import { MessageType } from "../utils/MessageType";
import ReservationService from "../services/ReservationService";
import { ReservationState } from "../utils/ReservationState";
import AdminService from "../services/AdminService";
import { APP_COMMISSION, API_URL } from "../config/config";
import AdminWalletService from "../services/AdminWalletService";
import WalletService from "../services/WalletService";
import { toast } from "react-toastify";
import NotificationService from "../services/NotificationService"
import {OperationsOnReservation} from "../utils/OperationsOnReservations"
const DiscussionContextProvider = ({ children, user }) => {
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [newSentMessage, setNewSentMessage] = useState(null);
  const [currentDiscussion, setCurrentDiscussion] = useState(null);
  const [recepientId, setRecepientId] = useState(null);
  const [currentDiscussionMessages, setCurrentDiscussionMessages] =
    useState(null);
  const [discussions, setDiscussions] = useState([]);
  const [typers, setTypers] = useState([]);
  const [lastMessages, setLastMessages] = useState([]);
  const [sendCourse, setSendCourse] = useState(false);
  const [checkUnread, setCheckUnread] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // payment popup

  // Defining services
  const messageService = new MessageService();
  const studentService = new StudentService();
  const professorService = new ProfessorService();
  const reservationService = new ReservationService();
  const adminService = new AdminService();
  const adminWalletService = new AdminWalletService();
  const walletService = new WalletService();
  const notificationService = new NotificationService()

  const fetchUserDiscussions = async () => {
    if (
      user &&
      (user.type === UserType.Professor || user.type === UserType.Student)
    ) {
      try {
        const discussions = await messageService.getUserDiscussions(
          user.profil._id
        );

        /* let lastMessages = [];
        discussions.map((discussion) => {
          if (discussion?.lastMessage) {
            lastMessages.push(discussion.lastMessage);
          }
        });
        if(lastMessages.length > 0){

          setLastMessages(lastMessages.slice(0, 10))
        } */

        // Fetch recipients for each discussion
        /* const updatedDiscussions = await Promise.all(
          discussions.map(async (discussion) => {
            let recipient = null;
            let lastMessage = await messageService.getDiscussionLastMessage(
              discussion._id
            );
            const otherParticipantId = discussion.participants.find(
              (participant) => participant.userId !== user?.profil._id
            )?.userId;
            if (otherParticipantId) {
              if (
                discussion.participants.find(
                  (participant) => participant.userId === otherParticipantId
                )?.role === UserType.Professor
              ) {
                // Fetch user from the backend using professor service
                recipient = await professorService.getProfessorById(
                  otherParticipantId
                );
              } else if (
                discussion.participants.find(
                  (participant) => participant.userId === otherParticipantId
                )?.role === UserType.Student
              ) {
                // Fetch user from the backend using student service
                recipient = await studentService.getStudentById(
                  otherParticipantId
                );
              }
            }
            setReceipients((prev) => [...prev, recipient]);
            return { discussion, recipient, lastMessage };
          })
        ); */

        setDiscussions(discussions);
        return discussions;
      } catch (err) {
        //console.log(err);
      }
    }
  };

  const fetchCurrentDiscussionMessages = async () => {
    try {
      const messages = await messageService.getDiscussionMessages(
        currentDiscussion.discussion?._id
      );
      setCurrentDiscussionMessages(messages);
    } catch (err) {
      //console.log(err);
    }
  };

  const fetchDiscussionMessages = async (discussionId) => {
    const messages = await messageService.getDiscussionMessages(discussionId);
    return messages;
  };

  const fetchDiscussionLastMessage = async (discussion) => {
    const lastMessage = await messageService.getDiscussionLastMessage(
      discussion?.discussion?._id
    );
    discussion.lastMessage = lastMessage;
  };

  const fetchUserLastMessages = async (userId) => {
    const fetchedLastMessages = await messageService.getUserLastMessages(
      userId
    );
    setLastMessages(fetchedLastMessages);
  };

  const sendMessage = async (message) => {
    if (message.content || message.type === MessageType.Reservation) {
      message = {
        ...message,
        discussionId: currentDiscussion.discussion._id,
        seen: false,
      };
      const sentMessage = await messageService.sendMessage(message);
      setNewSentMessage(sentMessage);
      setCurrentDiscussionMessages((prevMessages) =>
        prevMessages ? [...prevMessages, sentMessage] : [sentMessage]
      );
      // update the last message displayed in a discussion when the message is sent
      fetchDiscussionLastMessage(currentDiscussion);
    }
  };

  const sendReservationMessage = async (message) => {
    message = { ...message, seen: false };
    try {
      const sentReservationMessage =
        await messageService.sendReservationMessage(message);
      setNewSentMessage(sentReservationMessage);
    } catch (err) {
      //console.log(err);
    }
  };

  const checkIsOnline = (userId) => {
    return onlineUsers && onlineUsers.some((user) => user.userId === userId);
  };

  const markMessageAsRead = async (id) => {
    const message = await messageService.markMessageAsRead(id);
    return message;
  };

  const markCurrentDiscussionMessagesAsRead = async (recepientId) => {
    if (currentDiscussion && currentDiscussionMessages) {
      const unreadMessages = currentDiscussionMessages.filter(
        (msg) => msg.seen === false && msg.senderId === recepientId
      );

      // Mark all unread messages as read
      const markAsReadPromises = unreadMessages.map(async (msg) => {
        await markMessageAsRead(msg._id);
      });

      // Wait for all messages to be marked as read
      await Promise.all(markAsReadPromises);
      setCurrentDiscussionMessages((prev) =>
        prev.map((msg) => ({
          ...msg,
          seen: true,
        }))
      );
      const lastMessage = {
        ...currentDiscussionMessages[currentDiscussionMessages?.length - 1],
        seen: true,
      };
      if (socket) {
        socket.emit("seen", {
          discussionId: currentDiscussion.discussion?._id,
          userId: user.profil._id,
          recepientId,
          lastMessage,
        });
      }
    }
  };

  const markDiscussionMessagesAsRead = async (discussionId, recepientId) => {
    const messages = await fetchDiscussionMessages(discussionId);
    const unreadMessages = messages.filter(
      (msg) => msg.seen === false && msg.senderId === recepientId
    );
    if (unreadMessages.length > 0) {
      // Mark all unread messages as read
      const markAsReadPromises = unreadMessages.map(async (msg) => {
        await markMessageAsRead(msg._id);
      });

      // Wait for all messages to be marked as read
      await Promise.all(markAsReadPromises);
      const lastMessage = { ...messages[messages.length - 1], seen: true };
      if (socket) {
        socket.emit("seen", {
          discussionId,
          userId: user.profil._id,
          recepientId,
          lastMessage,
        });
        await fetchUserLastMessages(user.profil._id);
      }
    }
  };

  // Notify server when the user starts typing
  const handleTypingStart = (userId, discussionId, recepientId) => {
    if (socket && currentDiscussion) {
      socket.emit("typing", { discussionId, userId, recepientId });
    }
  };

  // Notify server when the user stops typing
  const handleTypingStop = (userId, discussionId, recepientId) => {
    if (socket && currentDiscussion) {
      socket.emit("stopTyping", { discussionId, userId, recepientId });
    }
  };

  // to revoke a reservation by a professor
  const onRevoke = async (reservation) => {
    try {
      const revokedReservation = await toast.promise(
        reservationService.changeReservationState(
          reservation._id,
          ReservationState.Revoked,
          reservation.date,
          reservation.timeslot,
          true
        ),
        {
          pending: "Revocation en cours...",
          success: "Réservation révoquée avec succès",
          error: "Erreur serveur. Impossible de révoquer la réservation.",
        }
      );
      // Check if confirmedReservation is undefined (error occurred)
      if (revokedReservation === undefined) {
        return;
      }
      setReservation(revokedReservation);
      await refresh();
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur. Impossible de révoquer la réservation.");
    }
  };

  // to cancel a reservation by a professor
  const onCancel = async (reservation, bool) => {
    try {
      const canceledReservation = await toast.promise(
        reservationService.changeReservationState(
          reservation._id,
          ReservationState.Canceled,
          reservation.date,
          reservation.timeslot,
          bool
        ),
        {
          pending: "Annulation en cours...",
          success: "Réservation annulée avec succès",
          error: "Erreur serveur. Impossible d'annuler la réservation.",
        }
      );
      // Check if confirmedReservation is undefined (error occurred)
      if (canceledReservation === undefined) {
        return;
      }
      setReservation(canceledReservation);
      // do money staff if reservation is paid
      if(reservation.paiementState === true){
        await returnMoney(canceledReservation);
        await addAdminBalance(
          -canceledReservation?.totalAmount,
          -(canceledReservation?.totalAmount * APP_COMMISSION)
        );
        await addProfBalance(
          canceledReservation.professor?._id
            ? canceledReservation.professor?._id
            : canceledReservation.professor,
          -canceledReservation?.totalAmount,
          -(canceledReservation?.totalAmount * (1 - APP_COMMISSION))
        );
      }
      await refresh();
      sendNotificationEmail(canceledReservation?._id, OperationsOnReservation.CANCEL)
      return
    } catch (error) {
      console.error(error);
    }
  };

  // to abandon a reservation by a student
  const onAbandon = async (reservation) => {
    try {
      const abandonedReservation = await toast.promise(
        reservationService.changeReservationState(
          reservation._id,
          ReservationState.Abandoned,
          reservation.date,
          reservation.timeslot,
          true
        ),
        {
          pending: "Abandon en cours...",
          success: "Réservation abandonnée avec succès",
          error: "Erreur serveur. Impossible d'abandonner la réservation.",
        }
      );
      // Check if confirmedReservation is undefined (error occurred)
      if (abandonedReservation === undefined) {
        return;
      }
      setReservation(abandonedReservation);

      // if reservation is paid we check time to return money
      if (abandonedReservation.paiementState === true) {
        const reservationDate = new Date(reservation.date);
        const [hours, minutes] = reservation.timeslot.start
          .split(":")
          .map(Number);
        reservationDate.setHours(hours, minutes, 0, 0);

        const now = new Date();
        const diffInMilliseconds = Math.abs(now - reservationDate);
        const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        // return money if reservation is abondoned more 24 befor the reservation start time
        if (diffInHours > 0 && diffInHours >= 24) {
          await returnMoney(abandonedReservation);
          await addAdminBalance(
            -abandonedReservation?.totalAmount,
            -(abandonedReservation?.totalAmount * APP_COMMISSION)
          );
        }
      } 
      await refresh();
      sendNotificationEmail(abandonedReservation?._id, OperationsOnReservation.ABANDON )
      return
    } catch (error) {
      console.error(error);
    }
  };

  const sendNotificationEmail = (resId, operation)=>{
    try {
        notificationService.sendNotificationEmail(resId, operation)
        return
    } catch (error) {
        console.log("cannot send email", error)
        return null;
    }
}

  // to pay a reservation by a student
  const onPay = (reservation) => {
    setReservation(reservation);
    setShowPopup(true);
  };

  // to notify the app that a student paid the professor so that the reservation can become scheduled
  const onConfirm = async (reservation) => {
    try {
      const confirmedReservation = await toast.promise(
        reservationService.changeReservationState(
          reservation._id,
          ReservationState.Scheduled,
          reservation.date,
          reservation.timeslot,
          false
        ),
        {
          pending: "Confirmation en cours...",
          success: "Réservation confirmée avec succès",
          error: "Erreur serveur. Impossible de confirmer la réservation.",
        }
      );
      //if an error happend stop further actions
      // Check if confirmedReservation is undefined (error occurred)
      if (confirmedReservation === undefined) {
        return;
      }
      setReservation(confirmedReservation);

      const profVirtualCard = await professorService.getProfessorVirtualCard(
        reservation.professor?._id
          ? reservation.professor._id
          : reservation.professor
      );
      await professorService.updateVirtualCardBalance(
        profVirtualCard._id,
        reservation.totalAmount * (1 - APP_COMMISSION),
        "add"
      );

      await addProfBalance(
        reservation.professor?._id
          ? reservation.professor._id
          : reservation.professor,
        reservation.totalAmount,
        reservation.totalAmount * (1 - APP_COMMISSION)
      );
      await refresh();
      sendNotificationEmail(confirmedReservation?._id, OperationsOnReservation.CONFIRM)
      return
    } catch (error) {
      console.error(error);
    }
  };

  // function to insert in admin balance when something related to money transfer occur
  const addAdminBalance = async (rawAmount, netAmount) => {
    const balance = {
      netCurrentBalance: netAmount,
      day: new Date(),
    };

    await adminWalletService.addAdminBalance(balance);
    return;
  };

  // function to insert in prof balance when something related to money transfer occur
  const addProfBalance = async (idProf, rawAmount, netAmount) => {
    const balance = {
      rawEstimatedBalance: 0,
      netEstimatedBalance: 0,
      rawCurrentBalance: rawAmount,
      netCurrentBalance: netAmount,
      day: new Date(),
    };

    await walletService.addProfBalance(idProf, balance);
    return;
  };

  // to refrech messages after altering reservation
  const refresh = async () => {
    if (currentDiscussion) {
      await fetchCurrentDiscussionMessages();
      if (socket) {
        socket.emit("refresh", {
          discussionId: currentDiscussion.discussion?._id,
          userId: user.profil._id,
          recepientId,
        });
      }
    }
  };

  const returnMoney = async (reservation) => {
    const studentId = reservation.student._id
      ? reservation.student._id
      : reservation.student;
    const studentVCard = await studentService.getVirtualCardStudent(studentId);
    const adminVCard = await adminService.getAdminVirtualCard();
    const balance = reservation?.totalAmount * APP_COMMISSION;
    //update admin virtual card balance
    await adminService.updateVirtualCardBalance(
      adminVCard?._id,
      balance,
      "substract"
    );
    // add the amount to the student EscolaLik card
    await studentService.updateVirtualCardBalance(
      studentVCard?._id,
      balance,
      "add"
    );
  };

  const changeCurrentDiscussion = (disc) => {
    setCurrentDiscussion(disc);
  };

  // Fetching discussions when a user sign in
  useEffect(() => {
    const newSocket = io(API_URL);
    if (user) {
      setSocket(newSocket);
      fetchUserDiscussions();
      fetchUserLastMessages(user.profil._id);
    }

    return () => {
      if (newSocket) {
        console.log("called");
        newSocket.disconnect();
        setDiscussions([]);
        setCurrentDiscussion(null);
        setLastMessages([]);
      }
    };
  }, [user]);

  // Fetching messages of currentDiscussion
  useEffect(() => {
    const fetchData = async () => {
      if (currentDiscussion && currentDiscussion.discussion) {
        await fetchCurrentDiscussionMessages();
        const recepientId =
          currentDiscussion?.discussion?.participants[0].userId !=
          user?.profil._id
            ? currentDiscussion?.discussion?.participants[0].userId
            : currentDiscussion?.discussion?.participants[1].userId;
        setRecepientId(recepientId);
        await markDiscussionMessagesAsRead(
          currentDiscussion?.discussion?._id,
          recepientId
        );
        setCheckUnread(currentDiscussion?.discussion?._id);
      }
    };
    fetchData();
  }, [currentDiscussion]);

  useEffect(() => {
    //register a user
    if (user && socket) {
      socket.emit("addNewUser", user.profil._id);
    }

    return () => {
      if (socket) {
        /* socket.off("getOnlineUsers");
        socket.off("typing");
        socket.off("stopTyping");
        socket.off("seen"); */
      }
    };
  }, [socket]);

  useEffect(() => {
    if (socket) {
      // get online users
      socket.on("getOnlineUsers", (res) => {
        setOnlineUsers(res);
      });

      // Listen for typing events from the server
      socket.on("typing", (typers) => {
        setTypers(typers);
      });

      socket.on("stopTyping", (typers) => {
        setTypers(typers);
      });

      // Listening for seeing a message event
      socket.on("seen", async ({ discussionId, userId, recepientId, lastMessage }) => {
          if (
            currentDiscussion &&
            currentDiscussion?.discussion?._id === discussionId
          ) {
            const updatedMessages = await fetchDiscussionMessages(discussionId);
            if(updatedMessages){

              setCurrentDiscussionMessages(updatedMessages);
            }
          } else {
            const updatedDiscussions = [];
            for (let disc in discussions) {
              if (disc?.discussion?._id === discussionId) {
                updatedDiscussions.push({
                  ...disc,
                  lastMessage,
                });
              } else {
                updatedDiscussions.push(disc);
              }
            }
            if (updatedDiscussions.length > 0 && updatedDiscussions[0]?.lastMessage) {
              setDiscussions(updatedDiscussions);
            }
            
          }
        }
      );

      // refresh after modifying reservation state
      socket.on("refresh", async ({ discussionId, userId, recepientId }) => {
        if (
          currentDiscussionMessages &&
          currentDiscussionMessages[0]?.discussionId === discussionId
        ) {
          const messages = await fetchDiscussionMessages(discussionId);
          if (messages?.length > 0) {
            setCurrentDiscussionMessages(messages);
          }
        }
      });
    }
  }, [socket, currentDiscussion]);

  // send message in realtime
  useEffect(() => {
    if (!socket) return;
    socket.emit("sendMessage", { ...newSentMessage, recepientId });
    // Update the last message of the relevant discussion in the discussions array
    setDiscussions((prevDiscussions) =>
      prevDiscussions.map((discussion) =>
        discussion.discussion?._id === newSentMessage.discussionId
          ? { ...discussion, lastMessage: newSentMessage }
          : discussion
      )
    );
    setCheckUnread(newSentMessage?.discussionId);
    //fetchUserLastMessages(user.profil._id);
  }, [newSentMessage]);

  // receive message in realtime
  useEffect(() => {
    if (!socket) return;

    socket.on("getMessage", async (res) => {
      if (
        currentDiscussion &&
        currentDiscussion?.discussion?._id === res.discussionId
      ) {
        setCurrentDiscussionMessages((prev) => [...prev, res]);
        setCurrentDiscussion((prev) => ({ ...prev, lastMessage: res }));
        await markCurrentDiscussionMessagesAsRead(
          res.discussionId,
          recepientId
        );
      }
      await fetchUserLastMessages(user.profil._id);
      // Update the last message of the relevant discussion in the discussions array
      setDiscussions((prevDiscussions) =>
        prevDiscussions.map((discussion) =>
          discussion.discussion?._id === res.discussionId
            ? { ...discussion, lastMessage: res }
            : discussion
        )
      );
      setCheckUnread(res?.discussionId);
    });

    return () => {
      if (socket) {
        socket.off("getMessage");
      }
    };
  }, [socket, currentDiscussion]);

  return (
    <DiscussionContext.Provider
      value={{
        onlineUsers,
        setNewSentMessage,
        setRecepientId,
        currentDiscussion,
        setCurrentDiscussion,
        setCurrentDiscussionMessages,
        currentDiscussionMessages,
        discussions,
        setDiscussions,
        sendMessage,
        sendReservationMessage,
        checkIsOnline,
        handleTypingStart,
        handleTypingStop,
        typers,
        lastMessages,
        fetchDiscussionMessages,
        sendCourse,
        setSendCourse,
        checkUnread,
        setCheckUnread,
        reservation,
        setReservation,
        onAbandon,
        onCancel,
        onPay,
        onRevoke,
        onConfirm,
        showPopup,
        setShowPopup,
        fetchCurrentDiscussionMessages,
        refresh,
        socket,
        addAdminBalance,
        fetchUserDiscussions,
        changeCurrentDiscussion,
      }}
    >
      {children}
    </DiscussionContext.Provider>
  );
};

export default DiscussionContextProvider;
