import axios from 'axios';
import { API_URL } from '../config/config';

class NotificationService {
  
  constructor() {
    this.baseUrl = API_URL+'/mailNotification';
  }

  sendNotificationEmail(reservationId, operationOnReservation, newDate, newSlot) {
    try{
      console.log("resid", reservationId)
      console.log("ope", operationOnReservation, newDate, newSlot)
      axios.post(`${this.baseUrl}`, {reservationId, operationOnReservation, newDate, newSlot})
      return null;

    }
    catch(error){
      //console.log(error)
      throw error
    }
  }

  

  

  


}

export default NotificationService;
